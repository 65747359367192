import React from 'react'
import "./Home.css"

export default class NotFound extends React.Component {
   render() {
      return (
         <div class="lander"><p>Sorry. I'm not sure what you are looking for :-(</p></div>
      );

   }
}
